import React, { useState } from 'react';
// import { createCustomer } from '../../graphql/mutations';
// import { generateClient } from 'aws-amplify/api';
import { CustomerCreateForm } from '../../ui-components';

const Shipping = () => {
  // const client = generateClient();

  // const [ship, setShip] = useState({
  //   customer: '',
  //   orderno: '',
  //   dateoforder: '',
  //   product: '',
  //   prodcode: '',
  //   batchno: '',
  //   dateofpkg: '',
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setShip((prevShip) => ({
  //     ...prevShip,
  //     [name]: value,
  //   }));
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const requestBody = { value: ship.value };

  //     const newBatch = await client.graphql({
  //       query: createCustomer,
  //       variables: {
  //         input: requestBody,
  //       },
  //     });

  //     // Reset the form fields
  //     setShip({
  //       customer: '',
  //       orderno: '',
  //       dateoforder: '',
  //       product: '',
  //       prodcode: '',
  //       batchno: '',
  //       dateofpkg: '',
  //     });
  //   } catch (error) {
  //     // Handle error if the API request fails
  //     console.error('API Request Error:', error);
  //   }
  // };

  return (
    <div>
      

<CustomerCreateForm/>
    </div>
  );
};

export default Shipping;
