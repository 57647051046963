import React from 'react'

const Profile = ({user}) => {
  return (
    <div>
      User Profile
     
    </div>
  )
}

export default Profile
