import React, { useState } from 'react';
import {
  ProductCreateForm 
 } from './../../ui-components';

const AddProduct = () => {
  // const [product, setProduct] = useState({
  //   name: '',
  //   mrp: '',
  //   unit: 'liters',
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setProduct((prevProduct) => ({
  //     ...prevProduct,
  //     [name]: value,
  //   }));
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // You can perform additional actions with the 'product' state, such as sending it to a server or updating a list of products.
  //   console.log('Product added:', product);
  // };

  return (
    <div>
      <h2>Add Product</h2>
      {/* <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          MRP:
          <input
            type="text"
            name="mrp"
            value={product.mrp}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Select Liters or Kg:
          <select name="unit" value={product.unit} onChange={handleChange}>
            <option value="liters">Liters</option>
            <option value="kilograms">Kilograms</option>
          </select>
        </label>
        <button type="submit">Add Product</button>
      </form> */}
      <ProductCreateForm />
    </div>
  );
};

export default AddProduct;
