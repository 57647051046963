/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createCustomer } from "../graphql/mutations";
const client = generateClient();
export default function CustomerCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    customer: "",
    customerorderno: "",
    dateoforder: "",
    product: "",
    custprodcode: "",
    batchno: "",
    packqty: "",
    dateofpkg: "",
    packedby: "",
    packno: "",
  };
  const [customer, setCustomer] = React.useState(initialValues.customer);
  const [customerorderno, setCustomerorderno] = React.useState(
    initialValues.customerorderno
  );
  const [dateoforder, setDateoforder] = React.useState(
    initialValues.dateoforder
  );
  const [product, setProduct] = React.useState(initialValues.product);
  const [custprodcode, setCustprodcode] = React.useState(
    initialValues.custprodcode
  );
  const [batchno, setBatchno] = React.useState(initialValues.batchno);
  const [packqty, setPackqty] = React.useState(initialValues.packqty);
  const [dateofpkg, setDateofpkg] = React.useState(initialValues.dateofpkg);
  const [packedby, setPackedby] = React.useState(initialValues.packedby);
  const [packno, setPackno] = React.useState(initialValues.packno);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setCustomer(initialValues.customer);
    setCustomerorderno(initialValues.customerorderno);
    setDateoforder(initialValues.dateoforder);
    setProduct(initialValues.product);
    setCustprodcode(initialValues.custprodcode);
    setBatchno(initialValues.batchno);
    setPackqty(initialValues.packqty);
    setDateofpkg(initialValues.dateofpkg);
    setPackedby(initialValues.packedby);
    setPackno(initialValues.packno);
    setErrors({});
  };
  const validations = {
    customer: [{ type: "Required" }],
    customerorderno: [{ type: "Required" }],
    dateoforder: [{ type: "Required" }],
    product: [{ type: "Required" }],
    custprodcode: [{ type: "Required" }],
    batchno: [],
    packqty: [{ type: "Required" }],
    dateofpkg: [],
    packedby: [],
    packno: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          customer,
          customerorderno,
          dateoforder,
          product,
          custprodcode,
          batchno,
          packqty,
          dateofpkg,
          packedby,
          packno,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createCustomer.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "CustomerCreateForm")}
      {...rest}
    >
      <TextField
        label="Customer Name"
        isRequired={true}
        isReadOnly={false}
        value={customer}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer: value,
              customerorderno,
              dateoforder,
              product,
              custprodcode,
              batchno,
              packqty,
              dateofpkg,
              packedby,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.customer ?? value;
          }
          if (errors.customer?.hasError) {
            runValidationTasks("customer", value);
          }
          setCustomer(value);
        }}
        onBlur={() => runValidationTasks("customer", customer)}
        errorMessage={errors.customer?.errorMessage}
        hasError={errors.customer?.hasError}
        {...getOverrideProps(overrides, "customer")}
      ></TextField>
      <TextField
        label="Customer Order Number"
        isRequired={true}
        isReadOnly={false}
        value={customerorderno}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno: value,
              dateoforder,
              product,
              custprodcode,
              batchno,
              packqty,
              dateofpkg,
              packedby,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.customerorderno ?? value;
          }
          if (errors.customerorderno?.hasError) {
            runValidationTasks("customerorderno", value);
          }
          setCustomerorderno(value);
        }}
        onBlur={() => runValidationTasks("customerorderno", customerorderno)}
        errorMessage={errors.customerorderno?.errorMessage}
        hasError={errors.customerorderno?.hasError}
        {...getOverrideProps(overrides, "customerorderno")}
      ></TextField>
      <TextField
        label="Date of Order"
        isRequired={true}
        isReadOnly={false}
        type="date"
        value={dateoforder}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno,
              dateoforder: value,
              product,
              custprodcode,
              batchno,
              packqty,
              dateofpkg,
              packedby,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.dateoforder ?? value;
          }
          if (errors.dateoforder?.hasError) {
            runValidationTasks("dateoforder", value);
          }
          setDateoforder(value);
        }}
        onBlur={() => runValidationTasks("dateoforder", dateoforder)}
        errorMessage={errors.dateoforder?.errorMessage}
        hasError={errors.dateoforder?.hasError}
        {...getOverrideProps(overrides, "dateoforder")}
      ></TextField>
      <TextField
        label="Product Name"
        isRequired={true}
        isReadOnly={false}
        value={product}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno,
              dateoforder,
              product: value,
              custprodcode,
              batchno,
              packqty,
              dateofpkg,
              packedby,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.product ?? value;
          }
          if (errors.product?.hasError) {
            runValidationTasks("product", value);
          }
          setProduct(value);
        }}
        onBlur={() => runValidationTasks("product", product)}
        errorMessage={errors.product?.errorMessage}
        hasError={errors.product?.hasError}
        {...getOverrideProps(overrides, "product")}
      ></TextField>
      <TextField
        label="Customer Product Code"
        isRequired={true}
        isReadOnly={false}
        value={custprodcode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno,
              dateoforder,
              product,
              custprodcode: value,
              batchno,
              packqty,
              dateofpkg,
              packedby,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.custprodcode ?? value;
          }
          if (errors.custprodcode?.hasError) {
            runValidationTasks("custprodcode", value);
          }
          setCustprodcode(value);
        }}
        onBlur={() => runValidationTasks("custprodcode", custprodcode)}
        errorMessage={errors.custprodcode?.errorMessage}
        hasError={errors.custprodcode?.hasError}
        {...getOverrideProps(overrides, "custprodcode")}
      ></TextField>
      <TextField
        label="Batch number"
        isRequired={false}
        isReadOnly={false}
        value={batchno}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno,
              dateoforder,
              product,
              custprodcode,
              batchno: value,
              packqty,
              dateofpkg,
              packedby,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.batchno ?? value;
          }
          if (errors.batchno?.hasError) {
            runValidationTasks("batchno", value);
          }
          setBatchno(value);
        }}
        onBlur={() => runValidationTasks("batchno", batchno)}
        errorMessage={errors.batchno?.errorMessage}
        hasError={errors.batchno?.hasError}
        {...getOverrideProps(overrides, "batchno")}
      ></TextField>
      <TextField
        label="Pack Quantity"
        isRequired={true}
        isReadOnly={false}
        value={packqty}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno,
              dateoforder,
              product,
              custprodcode,
              batchno,
              packqty: value,
              dateofpkg,
              packedby,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.packqty ?? value;
          }
          if (errors.packqty?.hasError) {
            runValidationTasks("packqty", value);
          }
          setPackqty(value);
        }}
        onBlur={() => runValidationTasks("packqty", packqty)}
        errorMessage={errors.packqty?.errorMessage}
        hasError={errors.packqty?.hasError}
        {...getOverrideProps(overrides, "packqty")}
      ></TextField>
      <TextField
        label="Date of Package"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={dateofpkg}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno,
              dateoforder,
              product,
              custprodcode,
              batchno,
              packqty,
              dateofpkg: value,
              packedby,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.dateofpkg ?? value;
          }
          if (errors.dateofpkg?.hasError) {
            runValidationTasks("dateofpkg", value);
          }
          setDateofpkg(value);
        }}
        onBlur={() => runValidationTasks("dateofpkg", dateofpkg)}
        errorMessage={errors.dateofpkg?.errorMessage}
        hasError={errors.dateofpkg?.hasError}
        {...getOverrideProps(overrides, "dateofpkg")}
      ></TextField>
      <TextField
        label="Packedby"
        isRequired={false}
        isReadOnly={false}
        value={packedby}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno,
              dateoforder,
              product,
              custprodcode,
              batchno,
              packqty,
              dateofpkg,
              packedby: value,
              packno,
            };
            const result = onChange(modelFields);
            value = result?.packedby ?? value;
          }
          if (errors.packedby?.hasError) {
            runValidationTasks("packedby", value);
          }
          setPackedby(value);
        }}
        onBlur={() => runValidationTasks("packedby", packedby)}
        errorMessage={errors.packedby?.errorMessage}
        hasError={errors.packedby?.hasError}
        {...getOverrideProps(overrides, "packedby")}
      ></TextField>
      <TextField
        label="Packno"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={packno}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              customer,
              customerorderno,
              dateoforder,
              product,
              custprodcode,
              batchno,
              packqty,
              dateofpkg,
              packedby,
              packno: value,
            };
            const result = onChange(modelFields);
            value = result?.packno ?? value;
          }
          if (errors.packno?.hasError) {
            runValidationTasks("packno", value);
          }
          setPackno(value);
        }}
        onBlur={() => runValidationTasks("packno", packno)}
        errorMessage={errors.packno?.errorMessage}
        hasError={errors.packno?.hasError}
        {...getOverrideProps(overrides, "packno")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
