/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      customer
      customerorderno
      dateoforder
      product
      custprodcode
      batchno
      packqty
      dateofpkg
      packedby
      packno
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      customer
      customerorderno
      dateoforder
      product
      custprodcode
      batchno
      packqty
      dateofpkg
      packedby
      packno
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      customer
      customerorderno
      dateoforder
      product
      custprodcode
      batchno
      packqty
      dateofpkg
      packedby
      packno
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      mrp
      volume
      measure
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      mrp
      volume
      measure
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      mrp
      volume
      measure
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBatch = /* GraphQL */ `
  mutation CreateBatch(
    $input: CreateBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    createBatch(input: $input, condition: $condition) {
      id
      link
      batchNumber
      productName
      mfgDate
      expiryDate
      mrp
      quantity
      value
      measure
      usp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBatch = /* GraphQL */ `
  mutation UpdateBatch(
    $input: UpdateBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    updateBatch(input: $input, condition: $condition) {
      id
      link
      batchNumber
      productName
      mfgDate
      expiryDate
      mrp
      quantity
      value
      measure
      usp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBatch = /* GraphQL */ `
  mutation DeleteBatch(
    $input: DeleteBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    deleteBatch(input: $input, condition: $condition) {
      id
      link
      batchNumber
      productName
      mfgDate
      expiryDate
      mrp
      quantity
      value
      measure
      usp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
