import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import config from './../../amplifyconfiguration.json';
import { Button } from '@aws-amplify/ui-react';
import { listCustomers } from './../../graphql/queries';
import { deleteCustomer } from './../../graphql/mutations';
import QRCode from 'qrcode';


Amplify.configure(config); // Use AmplifyConfig instead of Amplify
const client = generateClient();

const Orders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchBatch();
  }, []);

  const fetchBatch = async () => {
    try {
      const batchData = await client.graphql({ query: listCustomers });
      const batchList = batchData.data.listCustomers.items;
      setOrders(batchList);
    } catch (error) {
      console.log('error on fetching batch', error);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const result = await client.graphql({
        query: deleteCustomer,
        variables: {
          input: {
            id: itemId,
          },
        },
      });
      console.log(result);
      fetchBatch(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handlePrint =async(order) => {
    const numSheets = Math.ceil(order.packno / 4); // Calculate the number of sheets needed
    let printableContent = '';
    const qrCodeUrl = await QRCode.toDataURL("http://www.oremaccess.com/");
  
    for (let sheetIndex = 0; sheetIndex < numSheets; sheetIndex++) {
      const startPackIndex = sheetIndex * 4;
      const endPackIndex = Math.min(startPackIndex + 4, order.packno); // Ensure not to exceed total packs
  
      // Generate content for this sheet
      const sheetContent = Array.from({ length: endPackIndex - startPackIndex }, (_, copyIndex) => {
        const packNumber = startPackIndex + copyIndex + 1;
        return `
          <div class="printable">
            <p>Demo for OREM ACCESS BIO INC</p>
            <p>SDF Block no:1,EPIP,TSIIC,Pashamylaram(V), Patancheru(M), Sanga Reddy(D) - 502307</p>
            <hr style="border: 1px solid black;">
            <div style="display: flex;">
            <table style="width:100%,">
            <tbody>
              <tr>
              <th>Customer Name</th>
              <td>:</td>
              <td>${order.customer}</td>
            </tr>
            <tr>
              <th>Customer Order Number</th>
              <td>:</td>
              <td>${order.customerorderno}</td>
            </tr>
            <tr>
              <th>Date of Order</th>
              <td>:</td>
              <td>${order.dateoforder}</td>
            </tr>
            <tr>
              <th>Product Name</th>
              <td>:</td>
              <td>${order.product}</td>
            </tr>
            <tr>
              <th>Customer Product Code</th>
              <td>:</td>
              <td>${order.custprodcode}</td>
            </tr>
            <tr>
              <th>Batch Number</th>
              <td>:</td>
              <td>${order.batchno}</td>
            </tr>
            <tr>
              <th>Pack Quantity</th>
              <td>:</td>
              <td>${order.packqty}</td>
            </tr>
            <tr>
              <th>Date of Packing</th>
              <td>:</td>
              <td>${order.dateofpkg}</td>
            </tr>
              <tr>
                <th>Pack Number</th>
              <td>:</td>
                <td>${packNumber}/${order.packno}</td>
              </tr>
              <tr>
              <th>Packed by</th>
              <td>:</td>
              <td>${order.packedby}</td>
            </tr>
              </tbody>
            </table>
            <div style="margin-top: 200px;">
              <img src="${qrCodeUrl}" alt="QR Code" style="width: 70px; height: 70px;">
  
              </div>
              </div>

            <hr style="border: 1px solid black;">
            <p>STORE IN A COOL PLACE</p>
          </div>
        `;
      }).join('');
  
      printableContent += sheetContent;
    }
  
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Order Print</title>
          <style>
            @page {
              size: A4;
              margin: 3%;
            }
            .printable {
              width: 45%;
              float: left;
              margin-right: 5%;
              margin-bottom: 25px;
              border: 1px solid #000;
              box-sizing: border-box;
              padding: 10px;
            }
            p {
              text-align: center;
             }
            .printable table {
              width: 100%;
            }
            th{
              text-align:left;
            }
          
            .clearfix {
              clear: both;
            }
            hr {
              margin: 10px 0;
            }
          </style>
        </head>
        <body>
          ${printableContent}
          <div class="clearfix"></div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  
  
  
  

  return (
    <div>
      <div style={{ padding: '12px', textAlign: 'center' }}>
        <h2>Orders List</h2>
        {orders.length === 0 ? (
          <p>No Orders</p>
        ) : (
          <Table striped style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Order Number</th>
                <th>Product Name</th>
                <th>Batch Number</th>
                <th>Quantity</th>
                <th>Pack No</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>{order.customer}</td>
                  <td>{order.customerorderno}</td>
                  <td>{order.product}</td>
                  <td>{order.batchno}</td>
                  <td>{order.packqty}</td>
                  <td>{order.packno}</td>
                  <td style={{ display: 'flex', gap: '2px' }}>
                    <Button
                      variation="primary"
                      onClick={() => handlePrint(order)}
                    >
                      Print
                    </Button>
                    <Button
                      variation="primary"
                      colorTheme="error"
                      onClick={() => handleDelete(order.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Orders;
